import * as React from 'react';
import { Link } from 'gatsby';
import '../index.scss';

// markup
const NotFoundPage = () => {
  return (
    <main className='not-found'>
      <title>Not found | The Cantú's</title>
      <h1>Page not found</h1>
      <Link to="/">GO HOME</Link>
    </main>
  );
};

export default NotFoundPage;
